import compact from 'lodash/compact'
import map from 'lodash/map'
type IKeyedUrlOption = {urlKey: keyof typeof URL_MAP; url?: never}
type IRawUrlOption = {urlKey?: never; url: string}
type IOptions = (IKeyedUrlOption | IRawUrlOption) & {
  params?: any
  withOrigin?: boolean
}

const PORTAL_URL = process.env.REACT_APP_PORTAL_URL || ''
const STARGATE_URL = window?.env?.stargateUrl.replace('$STARGATE_URL', '') || process.env.REACT_APP_STARGATE_URL
const CREDENTIALS_SETTING = process.env.REACT_APP_STARGATE_URL ? 'include' : 'same-origin'
const XHR_WITH_CREDENTIALS = Boolean(process.env.REACT_APP_STARGATE_URL)

/* eslint-disable spellcheck/spell-checker */
export const URL_MAP = {
  'bouncer.edit-profile': '/profile/edit',
  'bouncer.signin': '/profile/signin',
  'bouncer.signout': `${PORTAL_URL}/profile/signout`,
  'genetic-counseling-patients': `${PORTAL_URL}/here-for-you/`,
  'individuals.contact': `${PORTAL_URL}/individuals/contact/`,
  'individuals.family-testing': `${PORTAL_URL}/individual-faqs/testing#7ll846ImrUleeakzRSRbVZ`,
  'individuals.genetic-counseling.consent': `${PORTAL_URL}/individuals/genetic-counseling/consent`,
  'individuals.reproductive-genetic-testing.prenatal-diagnostic-testing':
    '/individuals/reproductive-genetic-testing/prenatal-diagnostic-testing/',
  'patient.dashboard': '/us/patients/dashboard/',
  'patient.family-testing': `${PORTAL_URL}/patients/family-testing/`,
  'patient.home': `${PORTAL_URL}/patients-home/`,
  'patient.register-test-step1': '/us/patients/register-test/step1/',
  'patient.resources': `${PORTAL_URL}/patients/resources/conditions/`,
  patient_support: `${PORTAL_URL}/individual-faqs`,
  'pio.test-selection': '/us/patients/order/',
  'portal_common.contact': `${PORTAL_URL}/contact/`,
  'portal_common.contact-us': `${PORTAL_URL}/contact-us/`,
  'portal_common.family-history': '/familyhistory/',
  'portal_common.fht_autologin': '/fht/autologin/',
  'portal-common.family': '/family/',
  'settings.contacts': '/settings/contacts',
}
/* eslint-enable spellcheck/spell-checker */

export default function invitaeUrl(options: IOptions): string {
  let url = URL_MAP[options.urlKey] || options.url
  if (options.withOrigin) {
    url = window.location.origin + url
  }
  const params = map(options.params, (param, key) => {
    if (Array.isArray(param)) {
      return map(param, value => `${key}=${value}`).join('&')
    }
    return `${key}=${param}`
  }).join('&')
  return compact([url, params]).join('?')
}

export {PORTAL_URL, STARGATE_URL, CREDENTIALS_SETTING, XHR_WITH_CREDENTIALS}
