import {TUserNotifications, UserNotificationTypes} from 'models/UserNotifications'

export interface IUserNotificationResponse {
  id: number
  requisition: number
  reqEid: string
  dateCreated: string
  relatedEntities: string[]
  notification: UserNotificationTypes
  resolvable: boolean
  resolvedOn: string
}

function deserialize(userNotifications: IUserNotificationResponse[]): TUserNotifications {
  return userNotifications.map(el => {
    const {id, notification, resolvable, requisition} = el
    return {
      custom: false,
      id,
      reqid: requisition ? requisition.toString() : undefined,
      resolvable,
      type: notification,
    }
  })
}

function serialize(userNotifications: TUserNotifications) {
  return userNotifications.map(el => {
    const {id, resolvable, type, reqid} = el
    return {
      id,
      notification: type,
      requisition: reqid,
      resolvable,
    }
  })
}

export default {
  deserialize,
  serialize,
}
