import {IRequisition, IRequisitionStage, PioTag, TRequisitions} from 'models/Requisition'
import {RequisitionStageType} from 'models/RequisitionStageType'

export enum UserNotificationTypes {
  ResultsReleased = 'RESULTS_RELEASED',
  PaymentSelection = 'PAYMENT_SELECTION',
  SendSalivaKit = 'SEND_SALIVA_KIT',
  FillHealthHistoryForms = 'FILL_HEALTH_HISTORY_FORMS',
  KitActivationRequired = 'KIT_ACTIVATION_REQUIRED',
  KitActivationAndHealthHistoryFormsRequired = 'KIT_ACTIVATION_AND_HEALTH_HISTORY_FORMS_REQUIRED',
  ShowKitInstructions = 'SHOW_KIT_INSTRUCTIONS',
  PaymentRequired = 'OUTSTANDING_PATIENT_PAY',
  PatientCostEstimate = 'PATIENT_COST_ESTIMATE',
}

export interface IUserNotification {
  id: string | number
  type: UserNotificationTypes
  resolvable: boolean
  reqid: string
  custom: boolean
}

export type TUserNotifications = IUserNotification[]

export const userNotificationsFactory = {
  create(reqs: TRequisitions) {
    const userNotifications: TUserNotifications = []
    this.index = 0

    reqs
      .filter(req => req.isPatientInitiated)
      .reduce((acc, req) => this.createPioNotificationsByReq(req, acc), userNotifications)

    return userNotifications
  },

  createPioNotificationsByReq(req: IRequisition, userNotifications: TUserNotifications) {
    const {reqid, stepsCompleted, has_sample_failure} = req
    const isFormsSubmittedRequired = !this.hasStep(stepsCompleted, PioTag.FormsSubmitted)
    const isKitActivationRequired = !this.hasStep(stepsCompleted, PioTag.KitActivated)

    let type

    // has_sample_failure and not activated but forms submitted - activate kit only
    // has_sample_failure and activated and forms submitted - activate kit only
    // has_sample_failure and not activated and forms not submitted - activate and complete forms
    // has_sample_failure and activated but forms not submitted - activate and complete forms

    // !has_sample_failure and activated and forms submitted - no notification
    // !has_sample_failure and activated but forms not submitted - complete forms
    // !has_sample_failure and not activated but forms submitted - activate kit only
    // !has_sample_failure and not activated and forms not submitted - activate and complete forms

    if (has_sample_failure) {
      if (isFormsSubmittedRequired) {
        type = UserNotificationTypes.KitActivationAndHealthHistoryFormsRequired
      } else {
        type = UserNotificationTypes.KitActivationRequired
      }
    } else {
      if (isFormsSubmittedRequired && isKitActivationRequired) {
        type = UserNotificationTypes.KitActivationAndHealthHistoryFormsRequired
      } else if (isFormsSubmittedRequired) {
        type = UserNotificationTypes.FillHealthHistoryForms
      } else if (isKitActivationRequired) {
        type = UserNotificationTypes.KitActivationRequired
      }
    }

    if (type) {
      userNotifications.push({
        custom: true,
        id: `custom_${this.index++}`,
        reqid,
        resolvable: false,
        type,
      })
    }

    const kitShippedStage = req.stages.find(
      (stage: IRequisitionStage) => stage.name === RequisitionStageType.KIT_SHIPPED,
    )
    const inTransitStage = req.stages.find((stage: IRequisitionStage) => stage.name === RequisitionStageType.IN_TRANSIT)
    const inProcessingStage = req.stages.find(
      (stage: IRequisitionStage) => stage.name === RequisitionStageType.PROCESSING,
    )

    const showKitInstructions =
      !isKitActivationRequired &&
      kitShippedStage?.startedAt &&
      !inTransitStage.startedAt &&
      !inProcessingStage.startedAt

    if (showKitInstructions) {
      userNotifications.push({
        custom: true,
        id: `custom_${this.index++}`,
        reqid,
        resolvable: false,
        type: UserNotificationTypes.ShowKitInstructions,
      })
    }

    return userNotifications
  },

  hasStep(stepsCompleted: PioTag[], step: PioTag) {
    return stepsCompleted.some(t => t === step)
  },

  index: 0,
}
