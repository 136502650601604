import {IAsyncModel} from 'models/AsyncModel'
import {IRequisition} from 'models/Requisition'
import {IUserNotification, TUserNotifications, userNotificationsFactory} from 'models/UserNotifications'
import {Dispatch} from 'redux'
import {Action, createAction, handleActions} from 'redux-actions'
import transformer, {IUserNotificationResponse} from 'transformers/UserNotificationTransformer'

import {getRequest, postRequest} from 'api'
import {API_USER_NOTIFICATION} from 'api/urls'
import {AppDispatch} from 'store/configureStore'

const basePath = 'pe-app/user-notifications'
const GET_NOTIFICATIONS = `${basePath}/GET_NOTIFICATIONS`
const GET_NOTIFICATIONS_COMPLETE = `${basePath}/GET_NOTIFICATIONS_COMPLETE`
const RESOLVE_NOTIFICATION = `${basePath}/RESOLVE_NOTIFICATION`
const RESOLVE_NOTIFICATION_COMPLETE = `${basePath}/RESOLVE_NOTIFICATION_COMPLETE`
const ADD_CUSTOM_NOTIFICATIONS = `${basePath}/ADD_CUSTOM_NOTIFICATIONS`

export const actionTypes = {
  ADD_CUSTOM_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_COMPLETE,
  RESOLVE_NOTIFICATION,
  RESOLVE_NOTIFICATION_COMPLETE,
}

export default handleActions<IAsyncModel<TUserNotifications>, any>(
  {
    [ADD_CUSTOM_NOTIFICATIONS]: (state, action: Action<TUserNotifications>) => {
      const notCustomItems = state.data.filter(item => !item.custom)

      return {
        data: [...notCustomItems, ...action.payload],
        loading: state.loading,
      }
    },
    [GET_NOTIFICATIONS]: state => {
      return {
        ...state,
        loading: true,
      }
    },
    [GET_NOTIFICATIONS_COMPLETE]: (state, action: Action<TUserNotifications>) => {
      const customNotifications = state.data.filter(item => item.custom)

      return {
        data: [...customNotifications, ...action.payload],
        loading: false,
      }
    },
    [RESOLVE_NOTIFICATION]: (state, action: Action<IUserNotification>) => {
      const index = state.data.findIndex(notification => notification.id === action.payload.id)
      const newData = [...state.data]
      newData.splice(index, 1)
      return {
        data: newData,
        loading: true,
      }
    },
    [RESOLVE_NOTIFICATION_COMPLETE]: (state, action: Action<TUserNotifications>) => {
      return {
        data: action.payload,
        loading: false,
      }
    },
  },
  {data: [], loading: true},
)

export const getAction = createAction(GET_NOTIFICATIONS)
export const getCompleteAction = createAction(GET_NOTIFICATIONS_COMPLETE)
export const resolveAction = createAction(RESOLVE_NOTIFICATION)
export const resolveCompleteAction = createAction(RESOLVE_NOTIFICATION_COMPLETE)
export const addCustomNotificationsAction = createAction(ADD_CUSTOM_NOTIFICATIONS)

export const getUserNotifications = () => (dispatch: Dispatch<AppDispatch>) => {
  dispatch(getAction())

  getRequest(API_USER_NOTIFICATION).then((resp: IUserNotificationResponse[]) => {
    const userNotifications = transformer.deserialize(resp)
    dispatch(getCompleteAction(userNotifications))
  })
}

export enum Cause {
  viewResultsButtonClicked = 'viewResultsButtonClicked',
  viewCostEstimateButtonClicked = 'viewCostEstimateButtonClicked',
  other = 'other',
}

export const resolveUserNotification =
  (notification: IUserNotification, cause: Cause = Cause.other) =>
  (dispatch: Dispatch<AppDispatch>) => {
    dispatch(resolveAction({...notification, cause}))

    postRequest(`${API_USER_NOTIFICATION}${notification.id}/resolve_notification/`).then(resp => {
      const userNotifications = transformer.deserialize(resp)
      dispatch(resolveCompleteAction(userNotifications))
    })
  }

export const addCustomNotifications = (requisitions: IRequisition[]) => (dispatch: Dispatch<AppDispatch>) => {
  const customNotifications = userNotificationsFactory.create(requisitions)
  dispatch(addCustomNotificationsAction(customNotifications))
}
